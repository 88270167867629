import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
// import logo from '../../logo3.png';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <span>
            <h2 id="logo1" style={{ display: 'inline-block' }}>&lt;</h2>
            <h3 id="logo1" style={{ display: 'inline-block' }}>CODE</h3>
            <h3 id="logo2" style={{ display: 'inline-block' }}>h1 /</h3>
            <h2 id="logo1" style={{ display: 'inline-block' }}>&gt;</h2>
          </span>
        </div>
        <div className="gpt3__navbar-links_container">
          {/* <p><a href="#home">Home</a></p> */}
          <p><a href="#features">Features</a></p>
          <p><a href="mailto:connect@codeh1.in">Get Started</a></p>
          <p><a href="#whyus">Why Us?</a></p>
          {/* <p><a href="#blog">Library</a></p> */}
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        {/* <p>Sign in</p> */}
        <a href="mailto:connect@codeh1.in">
          <button type="button">Begin the Journey</button>
        </a>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p><a href="#features">Features</a></p>
              <p><a href="mailto:connect@codeh1.in">Get Started</a></p>
              <p><a href="#whyus">Why Us?</a></p>
              {/* <p><a href="#home">Home</a></p>
              <p><a href="#wgpt3">What is GPT3?</a></p>
              <p><a href="#possibility">Open AI</a></p>
              <p><a href="#features">Case Studies</a></p>
              <p><a href="#blog">Library</a></p> */}
            </div>
            <div className="gpt3__navbar-menu_container-links-sign">
              {/* <p>Sign in</p> */}
              <a href="mailto:connect@codeh1.in">
                <button type="button">Begin the Journey</button>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
