import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Flexible Pricing to Suit Your Budget',
    text: 'At codeh1, we understand that every project and budget is unique. That’s why we offer flexible pricing options tailored to your specific needs. We believe in making high-quality website development accessible to everyone.',
  },
  {
    title: 'Code Maintenance and Ongoing Support',
    text: 'Your peace of mind is important to us. After we deliver your website, we don’t just disappear. We provide comprehensive code maintenance and ongoing support to ensure your website remains secure, up-to-date, and optimized.',
  },
  {
    title: 'No Hidden Fees, No Headaches',
    text: 'Transparency is our policy. With codeh1, you won’t encounter any hidden fees or surprise charges. We believe in clear communication and delivering results without giving you any unnecessary headaches.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">The Digital Future is Here, Ready for You to Embrace. Step into Tomorrow & Let&apos;s Build It Together.</h1>
      <p>Partner with Us for Affordable, Hassle-Free Web Development</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
