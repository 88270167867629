import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhyUs = () => (
  <div className="gpt3__whatgpt3 section__margin" id="whyus">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Our Website Development Services" text="Discover how we can transform your ideas into stunning websites that captivate your audience. We specialize in crafting unique online experiences tailored to your needs and goals." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Unlock the Potential of Your Online Presence</h1>
      {/* <p>Explore Our Offerings</p> */}
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Custom Web Solutions" text="Our team excels in creating custom websites designed to reflect your brand identity and engage your visitors. We turn your vision into a fully functional digital reality." />
      <Feature title="E-commerce Expertise" text="Looking to sell products online? We specialize in e-commerce solutions, complete with secure payment processing, product listings, and user-friendly shopping experiences." />
      <Feature title="Responsive Design" text="In today's mobile-driven world, we ensure your website looks and functions flawlessly on all devices. Your audience will enjoy a seamless experience, no matter how they access your site." />
    </div>
  </div>
);

export default WhyUs;
