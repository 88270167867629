import React from 'react';
// import { slack, atlassian, dropbox, shopify } from './imports';
import { business, ecommerce, portfolio, blogs, personal } from './imports';
import './brand.css';

const Brand = () => (
  <div className="gpt3__brand section__padding">
    <div>
      <img src={business} />
      <div className="gpt3__header-content">
        <p>Business or Corporate Websites</p>
      </div>
    </div>
    <div>
      <img src={ecommerce} />
      <div className="gpt3__header-content">
        <p>E-commerce Websites</p>
      </div>
    </div>
    <div>
      <img src={portfolio} />
      <div className="gpt3__header-content">
        <p>Portfolio Websites</p>
      </div>
    </div>
    <div>
      <img src={blogs} />
      <div className="gpt3__header-content">
        <p>Blogging Websites</p>
      </div>
    </div>
    <div>
      <img src={personal} />
      <div className="gpt3__header-content">
        <p>Personal Websites</p>
      </div>
    </div>
  </div>
);

export default Brand;
