import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Footer, Possibility, Features, WhyUs, TermsAndConditions, Header } from './containers';
import { CTA, Brand, Navbar } from './components';

import './App.css';

const App = () => (
  <Router>
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
        <Header />
      </div>
      <Brand />
      <Features />
      <WhyUs />
      <Possibility />
      <CTA />
      <Footer />
    </div>
    <Routes>
      <Route path="/terms" element={<TermsAndConditions />} />
      {/* <Route path="/privacypolicy" element={<PrivacyPolicy />} /> */}
    </Routes>
  </Router>
);

export default App;
