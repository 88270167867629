import React from 'react';
import idea from '../../assets/idea.png';
// import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={idea} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      {/* <h4>Get Started</h4> */}
      <h1 className="gradient__text">The possibilities are <br /> beyond your imagination</h1>
      <p>Embarking on the journey of creating your dream website is easier than you think. We understand that sometimes, the technical aspects of web development can seem daunting, but that&apos;s where we come in. Our team is dedicated to providing you with seamless and enjoyable assistance throughout the entire process. We&apos;ll handle the complexities, leaving you with the freedom to focus on your ideas and vision. Let&apos;s turn your aspirations into an extraordinary online reality!</p>
      {/* <h4>Get Started</h4> */}
    </div>
  </div>
);

export default Possibility;
