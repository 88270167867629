import React from 'react';
// import Article from '../../components/article/Article';
// import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const TermsAndConditions = () => (
  <div className="gpt3__blog section__padding" id="terms">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">Terms & Conditions</h1>
    </div>
    <div className="gpt3__blog-container">

      <div className="gpt3__blog-container_groupB">
        {/* Placeholder content for Terms & Conditions */}
        <div>
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing and using the services provided by codeh1, including but not limited to the creation of websites,you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our services.</p>
        </div>
        <div>
          <h2>2. Service Description</h2>
          <p>codeh1 offers website creation services, including but not limited to business websites, e-commerce websites,portfolio websites, and other web-related services. The scope of each project will be defined in a separate agreement or proposal.</p>
        </div>
        <div>
          <h2>3. Payments</h2>
          <p>a. Payment for our services will be as agreed upon in the project proposal or agreement.</p>
          <p>b. Payments are due according to the payment schedule outlined in the agreement.</p>
          <p>c. Failure to make timely payments may result in a suspension of services or additional fees</p>
        </div>
        <div>
          <h2>4. Content and Materials</h2>
          <p>a. You are responsible for providing all necessary content and materials required for the website creation process.</p>
          <p>b. You agree that all content provided is accurate, lawful, and does not infringe upon any third-party rights.</p>
        </div>
        <div>
          <h2>5. Intellectual Property</h2>
          <p>a. You retain ownership of any content, images, and materials provided by you.</p>
          <p>b. Upon full payment, codeh1 grants you a non-exclusive, non-transferable license to use the website created for your project.</p>
        </div>
        <div>
          <h2>6. Project Timeline</h2>
          <p>a. codeh1 will make every effort to meet agreed-upon project timelines, but delays may occur due to unforeseen circumstances.</p>
          <p>b. We are not responsible for delays caused by your failure to provide necessary information or materials promptly.</p>
        </div>
        <div>
          <h2>7. Revisions and Changes</h2>
          <p>a. Revisions and changes to the website may be subject to additional fees, depending on the nature and extent of the alterations</p>
          <p>b. Any significant changes to the project scope will require a written change order.</p>
        </div>
        <div>
          <h2>8. Warranty</h2>
          <p>a. codeh1 warrants that the website will be free from defects in workmanship for a period of 1 year from the date of completion.</p>
          <p>b. This warranty does not cover issues caused by third-party plugins, software updates, or modifications made by parties other than codeh1.</p>
        </div>
        <div>
          <h2>9. Termination</h2>
          <p>codeh1 reserves the right to terminate services or suspend access to the website if you violate these Terms and Conditions or fail to make payments as agreed upon.</p>
        </div>
        <div>
          <h2>10. Limitation of Liability</h2>
          <p>codeh1 shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with our services.</p>
        </div>
        <div>
          <h2>11. Changes to Terms</h2>
          <p>codeh1 reserves the right to modify these Terms and Conditions at any time. Users will be notified of such changes via email or a notice on the website.</p>
        </div>
        <div>
          <h2>12. Contact Information</h2>
          <p>For questions or concerns regarding these Terms and Conditions, please contact us at connect@codeh1.in</p>
        </div>
        <div className="gpt3__blog-heading">
          <h1 className="gradient__text">Privacy Policy</h1>
        </div>
        <div>
          <p>Your privacy is important to us. This Privacy Policy outlines the types of personal information we receive and collect when you use our services, as well as how we safeguard your information. By using our website and services, you agree to the terms of this Privacy Policy.</p>
          <br />
          <br />
          <br />
          <h2>1. Information We Collect</h2>
          <p>We may collect the following types of information:</p>
          <ul>
            <li>Personal Information: This includes your name, email address, and any other information you provide when contacting us or using our services.</li>
            <li>Usage Information: We may collect information about how you interact with our website and services, such as your IP address, browser type, and device information.</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We may use your information for the following purposes:</p>
          <ul>
            <li>To provide and maintain our services.</li>
            <li>To communicate with you, respond to your inquiries, and send updates.</li>
            <li>To improve our website and services.</li>
          </ul>

          <h2>3. Disclosure of Information</h2>
          <p>We do not share your personal information with third parties except in the following cases:</p>
          <ul>
            <li>With your consent.</li>
            <li>If required by law or to protect our legal rights.</li>
          </ul>

          <h2>4. Security</h2>
          <p>We take reasonable measures to protect your personal information from unauthorized access or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
          <br />
          <h2>5. Links to Other Websites</h2>
          <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of these websites before providing any personal information.</p>
          <br />
          <h2>6. Changes to this Privacy Policy</h2>
          <p>We reserve the right to update or change this Privacy Policy at any time. Any changes will be posted on this page, and the date of the last update will be modified accordingly.</p>
          <br />
          <h2>7. Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy, please contact us at connect@codeh1.in</p>
        </div>
        {/* Add more sections for your Terms & Conditions content as needed */}
      </div>
    </div>
  </div>
);

export default TermsAndConditions;

// import React from 'react';
// import Article from '../../components/article/Article';
// import { blog01, blog02, blog03, blog04, blog05 } from './imports';
// import './blog.css';

// const Blog = () => (
//   <div className="gpt3__blog section__padding" id="blog">
//     <div className="gpt3__blog-heading">
//       <h1 className="gradient__text">A lot is happening, <br /> We are blogging about it.</h1>
//     </div>
//     <div className="gpt3__blog-container">
//       <div className="gpt3__blog-container_groupA">
//         <Article imgUrl={blog01} date="Sep 26, 2021" text="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
//       </div>
//       <div className="gpt3__blog-container_groupB">
//         <Article imgUrl={blog02} date="Sep 26, 2021" text="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
//         <Article imgUrl={blog03} date="Sep 26, 2021" text="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
//         <Article imgUrl={blog04} date="Sep 26, 2021" text="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
//         <Article imgUrl={blog05} date="Sep 26, 2021" text="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
//       </div>
//     </div>
//   </div>
// );

// export default Blog;
