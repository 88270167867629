import React from 'react';
// import gpt3Logo from '../../logo.svg';
import './footer.css';
import { Link } from 'react-router-dom';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Do you want to be a step ahead in shaping your online future?</h1>
    </div>

    <div className="gpt3__footer-btn">
      <a href="mailto:connect@codeh1.in">
        <p>Connect With Us</p>
      </a>
    </div>

    <div className="gpt3__footer-links">
      {/* <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p>Crechterwoord K12 182 DK Alknjkcb, <br /> All Rights Reserved</p>
      </div> */}
      {/* <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Overons</p>
        <p>Social Media</p>
        <p>Counters</p>
        <p>Contact</p>
      </div> */}
      <div className="gpt3__footer-links_div">
        {/* <h4>Company</h4> */}
        <Link to="/terms">
          <p>Terms, Conditions & Privacy Policy</p>
        </Link>
        {/* <p>Contact</p> */}
      </div>

      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <a href="mailto:connect@codeh1.in">
          <p>connect@codeh1.in</p>
          <p>+91 7483529754</p>
        </a>
      </div>
    </div>
    <div className="gpt3__footer-copyright">
      <p>@2023 codeh1. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
