import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <h3>Ready to Take the First Step?</h3>
      <p>Connect with us and unlock a world of possibilities.</p>
    </div>
    <div className="gpt3__cta-btn">
      <a href="mailto:connect@codeh1.in">
        <button type="button">Let&apos;s Talk</button>
      </a>
    </div>
  </div>
);

export default CTA;
